import ArgentABI from "../abi/argent.json"
import Shares from "../abi/shares.json";
import ERC20 from "../abi/erc20.json";
import BN from "bn.js";

import * as starknet from "starknet"
import { useAccount } from "@starknet-react/core";

const TOKEN_ADDRESS = "0x049d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7"; // testnettede aynı
const NFT_CONTRACT = "0x05b5b1b7b6f683cf5c765cc5599fc05c16fddcfbe14846b6e2ec5ba9e3848b34"; // mainnet

const parseUint256 = (val) => starknet.number.hexToDecimalString(starknet.number.toHexString(starknet.uint256.uint256ToBN(val)));
const parseFelt = (val) => starknet.number.hexToDecimalString(starknet.number.toHexString(val));


export const prepareCallTxs = (amount, totalPrice, state) => {
    console.log(totalPrice)
    const totalAmountBN = starknet.uint256.bnToUint256(totalPrice);
    const mintCalls = Array(amount).fill(
        {
            contractAddress: NFT_CONTRACT,
            entrypoint: 'mint',
            calldata: [state]
        });
    
    const calls = [
        {
            contractAddress: TOKEN_ADDRESS,
            entrypoint: 'approve',
            calldata: [NFT_CONTRACT, totalAmountBN.low, totalAmountBN.high] // bura todo
            
        }, ...mintCalls
    ]
    return calls;
}

export const getPrice = async (_network, _state_id) => {
    // const provider = new starknet.Provider({ sequencer: { network: starknet.constants.NetworkName.SN_MAIN } });
    const provider = new starknet.Provider({ sequencer: { network: 'mainnet-alpha' } });
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);
    const price = await contract.price(_state_id);
    console.log(price) // doğru
    return parseUint256(price);
}

export const getWhitelisted = async(_state_id, _account, _proof) => {
    console.log(_state_id, _account, _proof)
    // const provider = new starknet.Provider({ sequencer: { network: starknet.constants.NetworkName.SN_MAIN } });
    const provider = new starknet.Provider({ sequencer: { network: 'mainnet-alpha' } });
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);
    const result = await contract.verify(_state_id, _account, _proof);
    console.log(result)
    return result;
}

export const getState = async(_state_id) => {
    // const provider = new starknet.Provider({ sequencer: { network: starknet.constants.NetworkName.SN_MAIN } });
    const provider = new starknet.Provider({ sequencer: { network: 'mainnet-alpha' } });
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);
    // const state = await contract.get_state(_state_id);
    const state = await contract.call("get_state", [_state_id])
    return state;
}

export const getPeriodTotalMintable = async (_network) => {
    const provider = new starknet.Provider({  sequencer: {
        network: _network === 'testnet' ? 'goerli-alpha' : 'mainnet-alpha'
      }})
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);

    const period_total = await contract.get_period_total_mint();
    return parseFelt(period_total);
}

export const getPeriodCurrentMinted = async (_network) => {
    const provider = new starknet.Provider({  sequencer: {
        network: _network === 'testnet' ? 'goerli-alpha' : 'mainnet-alpha'
      }})
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);

    const current = await contract.get_period_minted();

    return parseFelt(current);
}

export const getMintingState = async (_network) => {
    const provider = new starknet.Provider({  sequencer: {
        network: _network === 'testnet' ? 'goerli-alpha' : 'mainnet-alpha'
      }})
    const contract = new starknet.Contract(Shares, NFT_CONTRACT, provider);

    const state = await contract.minting_state();

    return parseFelt(state);
}